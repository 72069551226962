"use client";

import Image from "next/image";
import Link from "next/link";
import { useSearchParams } from "next/navigation";
import { useState, useEffect, useRef } from "react";
import StockTicker from "../components/stockTicker/stockTicker";
import { Icon } from "../core/icon";
import { createPortalSession, useSeller } from "../lib/api/useSeller";
import { Button } from '../components/ui/button';

export const navLinks = [
  {
    text: "About",
    link: "/about",
  },
  {
    text: "Blog",
    link: "/blog",
  },
  {
    text: "Pricing",
    link: "/pricing",
  },
];

export const ctaLinks = [
  {
    text: "Discord",
    link: "https://discord.gg/hKbvHAHvvU",
  },
];

const Header = () => {
  const [minimal, setMinimal] = useState(false);

  const searchParams = useSearchParams();
  let affiliate = searchParams?.get("affiliate") || "";
  let referral = searchParams?.get("referral") || "";
  let source = searchParams?.get("source") || "";
  const { seller, loading } = useSeller();

  const navRef = useRef<HTMLDivElement>(null);
  const showNavBar = () => {
    navRef.current ? navRef.current.classList.toggle("responsive_nav") : null;
  };

  useEffect(() => {
    const handleResize = () => {
      setMinimal(window.innerWidth < 900);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    console.log(minimal);
    return () => window.removeEventListener('resize', handleResize);
  }, [minimal]);

  const trackAddToCartFacebookConversion = () => {
    if (affiliate == "Facebook") {
      const pixelId = process.env.NEXT_PUBLIC_META_PIXEL_ID || "";
      const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      };
      import("react-facebook-pixel")
        .then((module) => module.default)
        .then((ReactPixel) => {
          ReactPixel.init(pixelId, undefined, options);
          ReactPixel.track("AddToCart");
        });
    }
  };

  const trackPricingPageFacebookConversion = () => {
    if (affiliate == "Facebook") {
      const pixelId = process.env.NEXT_PUBLIC_META_PIXEL_ID || "";
      const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      };
      import("react-facebook-pixel")
        .then((module) => module.default)
        .then((ReactPixel) => {
          ReactPixel.init(pixelId, undefined, options);
          ReactPixel.track("ViewContent");
        });
    }
  };

  let paramsArray = [];
  if (affiliate !== "") {
    paramsArray.push(`affiliate=${affiliate}`);
  }

  if (referral !== "") {
    paramsArray.push(`referral=${referral}`);
  }

  if (source !== "") {
    paramsArray.push(`source=${source}`);
  }
  let params = paramsArray.length > 0 ? `?${paramsArray.join("&")}` : "";

  return (
    <>
      <span className="fixed-header-spacer"></span>
      <div className="fixed-header-container">
        {/* <StockTicker></StockTicker> */}
        <header className="[ launch-header ]">
          <Link href={"/" + params}>
            <picture>
              <source srcSet="/sellRazeLogo.png" media="(min-width: 600px)" height="48" width="170" />
              <Image src="/sellraze_mark.svg" width="36" height="36" alt="Sellraze landing page" />
            </picture>
          </Link>
          <nav className="[ launch-nav transform ] absolute right-0" ref={navRef}>
            <>
              {minimal && (
                <Link href="/" rel="noopener noreferrer" className="[ landing-link login-nav ]">
                  Home
                </Link>
              )}
              {navLinks.map((link) => (
                <Link
                  className="[ landing-link ] font-sans"
                  href={link.link + params}
                  key={`${link.link} + ${link.text}`}
                >
                  {link.text}
                </Link>
              ))}
              <div className={`flex ${minimal ? 'flex-col space-y-4' : 'flex-row space-x-4'} align-center justify-center`}>
                {!minimal && (
                  <Link
                  className="[ ss-large roundedfull ] bg-[#FAFAFA] rounded-full p-4"
                  href={"https://discord.gg/hKbvHAHvvU" + params}
                  key="https://discord.gg/hKbvHAHvvU"
                  target="_blank"
                  rel="noopener noreferrer"
                  >
                    <Image src="/discord_badge.svg" alt="" height="18" width="18" />
                  </Link>
                )}

                <Button variant="secondary" className={`${minimal ? 'bg-gray-200' : ''}`} href="/login">
                  <span className={`font-semibold ${minimal ? 'text-[18px]' : ''}`}>Sign up</span>
                </Button>
                <Button variant="default" href="/login">
                  <span className={`font-semibold ${minimal ? 'text-[18px]' : ''}`}>Log in</span>
                </Button>
              </div>
              {minimal && (
                <div className="flex flex-row space-x-4">
                  <Link
                  className="[ ss-large roundedfull ] bg-gray-200 rounded-full p-4"
                    href={"https://discord.gg/hKbvHAHvvU" + params}
                    key="https://discord.gg/hKbvHAHvvU"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image src="/discord_badge.svg" alt="" height="18" width="18" />
                  </Link>
                  <Link
                  className="[ ss-large roundedfull ] bg-gray-200 rounded-full p-4"
                    href={"https://twitter.com/SellRaze" + params}
                    key="https://twitter.com/SellRaze"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image src="/twitter.svg" alt="" height="18" width="18" />
                  </Link>
                  <Link
                  className="[ ss-large roundedfull ] bg-gray-200 rounded-full p-4"
                    href={"https://www.instagram.com/sellraze" + params}
                    key="https://www.instagram.com/sellraze"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Image src="/instagram.svg" alt="" height="18" width="18" />
                  </Link>
                </div>
              )}
            </>
            <div onClick={showNavBar} className="[ hamburger close-navbar ] cursor-pointer">
              <Icon icon="x-close" width="44" height="44" />
            </div>
          </nav>
          <div className="[ flow-row-large full-screen]">
            <div onClick={showNavBar} className="[ hamburger ] cursor-pointer">
              <Icon icon="hamburger-menu" width="35" height="45" />
            </div>

            {minimal ? (
              // <Link
              //   className="[ button ss-large ]"
              //   href={"/login" + params}
              //   data-variant="black"
              //   onClick={trackAddToCartFacebookConversion}
              // >
              //   Log in
              // </Link>
              <div></div>
            ) : (
              <>
                {/* <Link className="[ ss-large ]" href={"login" + params}>
                  Log In
                </Link> */}
                <div></div>
                {/* {!loading && seller && seller.billing && seller.billing.subscription_status != "active" ? (
                  <button
                    className="[ button ] [ notbold width-fit gap-half ]"
                    onClick={() => {
                      createPortalSession(seller, affiliate, source, referral);
                    }}
                    data-variant="black"
                  >
                    <p className="[ text-16 ]">Manage Subscription</p>
                  </button>
                ) : (
                  // <Link
                  //   className="[ button ss-large rounded-full roundedfull  ]"
                  //   href={"/register" + params}
                  //
                  //   onClick={trackAddToCartFacebookConversion}
                  // >
                  //   Login
                  // </Link>
                  <a
                    className="[ button ss-large roundedfull ] rounded-full px-4 py-2"
                    href="https://discord.gg/hKbvHAHvvU"
                    target="_blank"
                    data-variant="black"
                    rel="noopener noreferrer"
                  >
                    Discord
                  </a>
                )} */}
              </>
            )}
          </div>
        </header>
      </div>
    </>
  );
};
export default Header;
