import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "@/src/lib/utils"
import Link from "next/link";

const buttonVariants = cva(
  `inline-flex items-center justify-center w-full whitespace-nowrap tracking-[-0.2px] rounded-full text-md font-sans font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50`,
  {
    variants: {
      variant: {
        default: "bg-sellRazeBlue text-white hover:bg-sellRazeBlue/90",
        destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline: {
          light: "border-black text-black hover:bg-white hover:bg-opacity-10",
          dark: "border-[#242424] text-white hover:bg-gray-900 hover:bg-opacity-10"
        },
        secondary: "bg-[#FAFAFA] text-sellRazeBlue hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
        light: "bg-black text-white hover:bg-gray-100",
        dark: "bg-white text-black hover:bg-gray-300"
      },
      size: {
        default: "px-5 py-3",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  href?: string
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, href = "", ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    const button = (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    )

    return href ? (
      <Link href={href} passHref>
        {button}
      </Link>
    ) : (
      button
    )
  }
)
Button.displayName = "Button"

export { Button, buttonVariants }
